<script>
import TablePage from '../../../../components/table_page';
import Modal from '../../../../components/modal';
import { Form, accessConfig } from './form';
import associatedPosition from './form/associated_position/associated_position.vue';
import customerUser from './form/customer_user/associated_position.vue';

export default {
  name: 'proportion',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/mdm/mdmRoleController/pageList',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Modal,
    Form,
    accessConfig,
    associatedPosition,
    customerUser,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '380px';
        this.formConfig = {
          type: val.code,
          id: null,
        };
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '380px';
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '380px';
        this.formConfig = {
          ...val,
          ...row,
          type: val.code,
          id: row.id,
        };
        this.openModal();
      } else if (val.code === 'related_position') {
        this.modalConfig.title = '关联职位';
        this.formConfig = row;
        this.formName = 'associatedPosition';
        this.openFull();
      } else if (val.code === 'associated_customer_users') {
        this.modalConfig.title = '关联客户用户';
        this.formConfig = row;
        this.formName = 'customerUser';
        this.openFull();
      } else if (val.code === 'journal') {
        return false;
      } else if (val.code === 'permission_configuration') {
        this.modalConfig.title = '功能权限';
        this.formConfig = row;
        this.formName = 'accessConfig';
        this.openFull();
      } else if (val.code === 'data_permission') {
        // 数据权限
        this.$router.push({
          path: '/mdm_center/data_auth',
          query: {
            roleCode: row.roleCode,
          },
        });
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          type: val.code,
          id: row.id,
          code: 'view',
        };
        this.openModal();
      }
    },
    // 删除操作
    deleteClick({ row }) {
      const ids = row ? [row.id] : this.selection;

      const title = '此操作将永久删除此数据, 是否继续?';
      const url = '/mdm/mdmRoleController/delete';
      const msg = '删除';

      const data = {
        params: ids,
        url,
        title,
        msg,
      };
      this.messageBox(data);
    },
  },
  created() {
    this.getConfigList('mdm_role_manage');
  },
};
</script>
