<!--<template>-->
    <!--<div></div>-->
<!--</template>-->

<script>
// import configs from '../data';
// import Form from '../../../../../components/form';
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerThreeReplacePosition from './associated_position_three_replace_role.vue';

export default {
  extends: TablePage,
  components: {
    associatedCustomerThreeReplacePosition,
  },
  props: {
    userItemRowObj: {},

  },
  data() {
    return {
      // requestUrl: '/mdm/mdmUserController/pageList',
      // configs,
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '解除关联',
            buttonType: '0',
            buttonCode: 'delAssociated',

          },
          {
            name: '替换关联角色',
            buttonType: '0',
            buttonCode: 'replacePosition',

          },
        ],
        columns: [],
        tableConfig: {},
      },
      isCalculateHeight: false,
    };
  },
  created() {
    this.params = { ...this.params, roleCode: this.userItemRowObj.roleCode };
    this.getConfigList('mdm_role_rel_customer_user_has_rel_cur_role_list', true, true);
  },
  mounted() {
    this.rule = [];
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'delAssociated') {
        if (this.selectRow.length > 0) {
          // this.$confirm('确认提交吗？', '提示').then(() => {
          // const params = {
          //   userNameList: this.selectRow.map((a) => a.userName),
          //   roleCode: this.userItemRowObj.roleCode,
          // };
          request.post('/mdm/mdmCustomerUserController/unbindUserRelRole', this.selectRow.map((a) => a.relId)).then((res) => {
            if (res.success) {
              this.getList();
              this.$emit('refresh');
              this.selectRow = [];
            }
            this.$message({
              message: res.message,
              type: 'success',
            });
          });
          // });
        } else {
          this.$message('请勾选');
        }
      }

      if (val.buttonCode === 'replacePosition') {
        if (this.selectRow.length > 0) {
          this.formName = 'associatedCustomerThreeReplacePosition';
          this.modalConfig.title = '选择角色';
          this.modalConfig.showFooter = true;
          this.propsObjInData = this.userItemRowObj;
          this.openModal();
        } else {
          this.$message('请勾选');
        }
      }
    },

    onModalOk() {
      if (this.$refs.modalForm.selectRow.length > 0) {
        this.onSubmit(this.$refs.modalForm.selectRow);
      } else {
        this.$message('请勾选');
      }
    },

    // 确认事件 onSubmit

    onSubmit(roleArr) {
      const params = {
        ids: this.selectRow.map((a) => a.relId),
        roleCode: roleArr[0].roleCode,
      };
      request.post('/mdm/mdmCustomerUserController/replaceUserRelRole', params).then((res) => {
        if (res.success) {
          this.selectRow = [];
          this.getList();
          this.closeModal();
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
