var render, staticRenderFns
import script from "./associated_position_three_replace_role.vue?vue&type=script&lang=js&"
export * from "./associated_position_three_replace_role.vue?vue&type=script&lang=js&"
import style0 from "./associated_position_three_replace_role.vue?vue&type=style&index=0&id=5c413474&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c413474",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c413474')) {
      api.createRecord('5c413474', component.options)
    } else {
      api.reload('5c413474', component.options)
    }
    
  }
}
component.options.__file = "found/views/mdm/views/role_manage/form/customer_user/associated_position_three_replace_role.vue"
export default component.exports