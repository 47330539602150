<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        let url = '/mdm/mdmRoleController/save';
        const params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmRoleController/update';
          params.id = this.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
  mounted() {},
  async created() {
    await this.getFormRule('mdm_role_manage_form');
    this.setValue({ enableStatus: '009' });
    const roleCode = this.getRule('roleCode');
    roleCode.validate = [
      ...roleCode.validate,
      {
        trigger: 'blur',
        pattern: '^[a-zA-Z0-9]*$',
        message: '仅能输入英文和数字的组合',
      },
    ];
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request.get('/mdm/mdmRoleController/detail', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          this.row = res.result;
          this.setValue(res.result);
        }
      });
    }
  },
};
</script>
