<template>
  <div class="container">
    <el-form class="form-container" :model="mform" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="角色编码">
            <el-input v-model="mform.roleCode" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="角色名称">
            <el-input v-model="mform.roleName" placeholder="角色名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input type="textarea" v-model="mform.remarks" placeholder="备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="permission-contaier">
      <el-divider content-position="left">功能权限</el-divider>
      <el-tabs class="permission-tabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="PC端" name="0">
          <el-row class="menu-list">
            <el-col :span="8" class="menu-item borderRight">
              <el-tree
                :data="treeData"
                show-checkbox
                :expand-on-click-node="false"
                node-key="functionCode"
                highlight-current
                :default-checked-keys="defaultCheckedTree"
                :props="defaultProps"
                @check="treeCheck"
                @node-click="getMenuList"
                ref="menuTree"
              >
              </el-tree>
            </el-col>
            <el-col :span="8" class="menu-item borderRight">
              <div>
                <div
                  class="menu-list-item"
                  @click="getButtonData(item)"
                  :class="{ 'list-active': item.id === selectList }"
                  v-for="item in listData"
                  :key="item.id"
                >
                  {{ item.functionName }}
                </div>
              </div>
            </el-col>
            <el-col :span="8" class="menu-item">
              <div class="button-permissions">按钮权限</div>
              <el-checkbox-group v-model="checkButton" @change="setButttonSelect">
                <el-checkbox
                  v-for="(item, index) in buttonData"
                  :key="index"
                  :label="item.primaryKey"
                  >{{ item.buttonName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="小程序端" name="3">小程序端</el-tab-pane>
      </el-tabs>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit" size="small" icon="el-icon-check">提交 </el-button>
      <el-button type="danger" @click="closeModal" size="small" icon="el-icon-close"
        >关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import request from '../../../../../utils/request';

export default {
  props: {
    formConfig: Object,
  },
  data() {
    return {
      mform: {
        roleCode: '',
        roleName: '',
        remarks: '',
      }, // 表单数据
      activeName: '0', // tab选中
      treeData: [], // 菜单树数据
      defaultProps: {
        children: 'children',
        label: 'functionName',
      },
      selectList: '',
      defaultCheckedTree: [], // 树默认选中
      listData: [], // 选择的菜单下面的数据
      buttonData: [], // 当前选择菜单下的列表数据
      checkButton: [], // 选择所有的按钮
      selectMenu: [], // 当前选中菜单
      allButtonData: [], // 所有的按钮数据
      selectListData: {}, // 当前选中的列表数据
    };
  },
  created() {
    // this.getDetail();
    this.getTreeData();
  },
  methods: {
    submit() {
      this.setButttonSelect();
      this.setMenuSelect(this.treeData);
      console.log(this.allButtonData);
      console.log(this.treeData);
      // this.$emit('onClose');
      request
        .post('/mdm/mdmRoleController/saveFunctionButton', {
          mdmRoleFunctionTreeVos: this.treeData,
          mdmRoleSubButtonVos: this.allButtonData,
          ...this.mform,
        })
        .then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
    closeModal() {
      this.$emit('onClose');
    },
    // 复选框选中
    async treeCheck(data, node) {
      const { functionCode } = data;
      const status = node.checkedKeys.includes(functionCode);
      await request.post('/mdm/mdmRoleController/switchFunctionButton', {
        functionCode,
        roleCode: this.mform.roleCode,
        status: status ? 1 : 0,
      }).then((res) => {
        this.getButtonData(this.selectListData);
      });
    },
    // 切换tab
    handleClick(e) {
      console.log(e);
    },
    // 获取菜单树
    async getTreeData() {
      this.mform.roleCode = this.formConfig.roleCode;
      const res = await request.post('/mdm/mdmRoleController/roleFunctionTree', {
        functionConfig: this.activeName,
        roleCode: this.formConfig.roleCode,
      });
      this.mform = {
        roleCode: res.result.roleCode,
        roleName: res.result.roleName,
        remarks: res.result.remarks,
      };
      this.treeData = res.result.mdmRoleFunctionTreeVos;
      this.setTreeSelected(this.treeData);
    },
    // 设置树默认选中
    setTreeSelected(data) {
      data.forEach((v) => {
        const item = v;
        if (item.functionSelect === '1') {
          this.defaultCheckedTree.push(item.functionCode);
        }
        if (item.children.length > 0) {
          this.setTreeSelected(item.children);
        }
      });
    },
    // 获取菜单下面列表
    async getMenuList(node) {
      const res = await request.post('/mdm/mdmfunctionsub/sub_list', {
        functionConfig: this.activeName,
        roleCode: this.formConfig.roleCode,
        parentCode: node.functionCode,
        attachedButton: 1,
      });
      this.listData = res.result;
      this.buttonData = [];
      this.selectList = '';
    },
    // 获取按钮数据
    async getButtonData(item) {
      this.selectList = item.id;
      this.selectListData = item;
      if (!item.parentCode || !item.functionCode) {
        return false;
      }
      this.setButttonSelect();
      const res = await request.post('/mdm/mdmRoleController/roleButtonList', {
        roleCode: this.formConfig.roleCode,
        parentCode: item.parentCode,
        functionCode: item.functionCode,
      });
      this.buttonData = res.result;
      this.buttonData.map((v) => {
        const itemv = v;
        itemv.primaryKey = itemv.buttonCode + itemv.functionCode + itemv.parentCode;
        return itemv;
      });
      this.buttonData.forEach((v) => {
        const itemk = v;
        if (!this.allButtonData.map((k) => k.primaryKey).includes(itemk.primaryKey)) {
          this.allButtonData.push(itemk);
        } else {
          this.allButtonData = this.allButtonData.map((l) => {
            const iteml = l;
            if (iteml.primaryKey === itemk.primaryKey) {
              itemk.buttonSelect = iteml.buttonSelect;
              return itemk;
            }
            return iteml;
          });
        }
      });
      this.getSelectButtton();
    },
    // 动态设置所有按钮状态
    setButttonSelect() {
      const tempCheckButton = this.checkButton;
      this.allButtonData.map((v) => {
        const item = v;
        if (tempCheckButton.includes(item.primaryKey)) {
          item.buttonSelect = '1';
        } else {
          item.buttonSelect = '0';
        }
        return item;
      });
      // this.checkButton = [];
    },
    // 获取当前选中按钮选项
    getSelectButtton() {
      console.log(this.allButtonData);
      this.checkButton = this.allButtonData
        .filter((item) => item.buttonSelect === '1')
        .map((item) => item.primaryKey);
      console.log(this.checkButton);
    },
    // 动态设置所有菜单勾选状态
    setMenuSelect(data) {
      const selectMenu = this.$refs.menuTree.getCheckedKeys();
      data.forEach((v) => {
        const item = v;
        if (selectMenu.includes(item.functionCode)) {
          item.functionSelect = '1';
        } else {
          item.functionSelect = '0';
        }
        if (item.children.length > 0) {
          this.setMenuSelect(item.children);
        }
        return item;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .permission-contaier {
    flex: 1;
    overflow: hidden;
    position: relative;
    .permission-tabs {
      position: absolute;
      right: 0;
      top: 40px;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      .menu-item {
        height: 100%;
        padding: 0 10px;
        overflow: scroll;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        .button-permissions {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .menu-item::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }
}
/deep/.el-tabs__content {
  height: 100%;
}
/deep/.el-tab-pane {
  height: 100%;
}
/deep/.el-checkbox__label {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400 !important;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
.menu-list-item {
  line-height: 26px;
  font-size: 14px;
  cursor: pointer;
}
.menu-list {
  height: 100%;
  .borderRight {
    border-right: 2px solid rgb(219, 219, 219);
  }
}
.list-active {
  background-color: #f0f7ff;
}
</style>
