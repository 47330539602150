var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "associated_customer" }, [
    _c(
      "div",
      { staticClass: "associated_customer_up" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": function () {} },
            model: {
              value: _vm.activeTabPaneName,
              callback: function ($$v) {
                _vm.activeTabPaneName = $$v
              },
              expression: "activeTabPaneName",
            },
          },
          [
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: "未关联任何角色的客户用户",
                  name: "associatedCustomerOne",
                },
              },
              [
                _c("associatedCustomerOne", {
                  ref: "one",
                  attrs: { userItemRowObj: _vm.formConfig },
                  on: { refresh: _vm.refresh },
                }),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: "未关联当前角色的客户用户",
                  name: "associatedCustomerTwo",
                },
              },
              [
                _c("associatedCustomerTwo", {
                  ref: "two",
                  attrs: { userItemRowObj: _vm.formConfig },
                  on: { refresh: _vm.refresh },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "associated_customer_line" }),
    _c(
      "div",
      { staticClass: "associated_customer_down" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card" },
            on: { "tab-click": function () {} },
            model: {
              value: _vm.activeTabPaneNameDown,
              callback: function ($$v) {
                _vm.activeTabPaneNameDown = $$v
              },
              expression: "activeTabPaneNameDown",
            },
          },
          [
            _c(
              "el-tab-pane",
              {
                attrs: {
                  label: "已关联当前角色的客户用户",
                  name: "associatedCustomerThree",
                },
              },
              [
                _c("associatedCustomerThree", {
                  ref: "three",
                  attrs: { userItemRowObj: _vm.formConfig },
                  on: { refresh: _vm.refresh },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "associated_customer_Btn" }, [
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onClose")
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }