var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-form",
        {
          staticClass: "form-container",
          attrs: { model: _vm.mform, "label-width": "80px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色编码" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.mform.roleCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.mform, "roleCode", $$v)
                          },
                          expression: "mform.roleCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "角色名称" },
                        model: {
                          value: _vm.mform.roleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.mform, "roleName", $$v)
                          },
                          expression: "mform.roleName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "备注" },
                        model: {
                          value: _vm.mform.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.mform, "remarks", $$v)
                          },
                          expression: "mform.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "permission-contaier" },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("功能权限"),
          ]),
          _c(
            "el-tabs",
            {
              staticClass: "permission-tabs",
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "PC端", name: "0" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "menu-list" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "menu-item borderRight",
                          attrs: { span: 8 },
                        },
                        [
                          _c("el-tree", {
                            ref: "menuTree",
                            attrs: {
                              data: _vm.treeData,
                              "show-checkbox": "",
                              "expand-on-click-node": false,
                              "node-key": "functionCode",
                              "highlight-current": "",
                              "default-checked-keys": _vm.defaultCheckedTree,
                              props: _vm.defaultProps,
                            },
                            on: {
                              check: _vm.treeCheck,
                              "node-click": _vm.getMenuList,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "menu-item borderRight",
                          attrs: { span: 8 },
                        },
                        [
                          _c(
                            "div",
                            _vm._l(_vm.listData, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "menu-list-item",
                                  class: {
                                    "list-active": item.id === _vm.selectList,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getButtonData(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.functionName) + " ")]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "menu-item", attrs: { span: 8 } },
                        [
                          _c("div", { staticClass: "button-permissions" }, [
                            _vm._v("按钮权限"),
                          ]),
                          _c(
                            "el-checkbox-group",
                            {
                              on: { change: _vm.setButttonSelect },
                              model: {
                                value: _vm.checkButton,
                                callback: function ($$v) {
                                  _vm.checkButton = $$v
                                },
                                expression: "checkButton",
                              },
                            },
                            _vm._l(_vm.buttonData, function (item, index) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: index,
                                  attrs: { label: item.primaryKey },
                                },
                                [_vm._v(_vm._s(item.buttonName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-tab-pane", { attrs: { label: "小程序端", name: "3" } }, [
                _vm._v("小程序端"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-check" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.closeModal },
            },
            [_vm._v("关闭 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }